import React from 'react'
import SEO from '../components/seo'

class NotFoundPage extends React.Component {
  render() {
    return (
      <>
        <SEO title="404: Not found" />
        <div className="text-l-minus mob-p-1 mob-pb-2_4 tab-p-2_4">
          <p>
            Page not found <br />
            Redirecting to homepage
          </p>
        </div>
      </>
    )
  }
  componentDidMount() {
    setTimeout(() => {
      window.location.href = '/'
    }, 3000)
  }
}

export default NotFoundPage
